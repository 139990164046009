import {append, insertBefore} from './scripts/utilities';
import DataLayer from './../lib/dataLayer';
import stickybits from 'stickybits';

export default class {

  constructor( adObject, tcData, plugins = [] ) {
    this.devMode = 0;
    this.adLibVersion = '4.18.01.30';
    this.getLog( 'adLibVersion: ' + this.adLibVersion );
    this.getLog( 'clientConfig: ' + adObject.configVersion );

    window.googletag = window.googletag || { cmd: [] };
    this.gptLibURL = [ 'https://securepubads.g.doubleclick.net/tag/js/gpt.js', 'https://pagead2.googlesyndication.com/tag/js/gpt.js' ];

    window.pbjs = window.pbjs || {};
    window.pbjs.que = window.pbjs.que || [];
    window._adexc = window._adexc || [];

    this.ads = window.ads || false;
    this.adObject = adObject;
    this.adQueue = window.adQueue || [];
    this.adSlots = [];
    this.myAdKeyords = window.adKeywords || [];
    this.uri = window.location.pathname;
    this.tcfData = tcData || {};
    this.cookiesDisable = false;
    this.nonPersonalizedAds = false;
    this.ltdAdsEnabled = false;
    this.blacklist = 0;
    this.deviceCategory = 'not_set';
    this.plugins = this.registerPlugins( plugins );
    this.integration = false;
    this.pbSlotIncubator = false;
    this.pbStorageAllowed = false;
    this.loadScript = [];
    this.bidder = [];
    this.bidder.definedAdSlots = [];
    this.bidder.refreshedAdSlots = [];
    this.bidder.biddingAdSlots = [];
    this.bidder.allBidsBack = false;
    this.bidder.bidTimeoutBuffer = false;
    this.bidTimeout = this.adObject.bidTimeout;
    this.failTimeout = this.bidTimeout + 250;
    // this.sharedIdPixel =  this.adObject.pb[0].pbSharedIdPixelURL;
    this.canonicalURI = document.querySelector( "link[rel='canonical']" ).href;
    this.adex_cookie = this.getCookie( this.adObject.adex_api[0].adex_cname );
    this.adex_segments = this.adObject.adex_api[0].adex_segments || [];
    this.adex_segments_str = 'not_set';
    this.dmp_1plusxKW = this.adObject.dmp_1plusx[0].dmp_1plusxKW || [];
    this.login_status = this.adObject.userLogin[0].login;

    this.mobile_375 = 0;
    this.mobile_700 = 0;
    this.tablet_701 = 0;
    this.tablet_1199 = 0;
    this.skyscraper_1200 = 0;
    this.fireplace_1320 = 0;
    this.sitebar_1460 = 0;
    this.contentChannel = this.myAdKeyords.includes('content_channel') || false;

    if ( this.devMode === 1 ) {
      this.getLog( ' DEV MODE ', 'red' );
      this.myAdKeyords.push( 'adtest' );
      this.myAdKeyords.push( 'no_ba' );
      this.myAdKeyords.push( 'no_google' );
      this.myAdKeyords.push( 'marc_test' );
    }

    this.getAdTest();
    this.blacklist = this.getBlacklist( this.uri, this.adObject );
    this.getLog( ' blacklist ' + this.blacklist + ' ', 'green' );

    const dataLayer = new DataLayer( window.dataLayer || [] );
    this.pageType = dataLayer.getKey( 'pageType' );
    this.login_status = dataLayer.getKey( 'login_status' ) || this.login_status;
    this.getLog( 'login_status: ' + this.login_status );

    this.getLog( 'tcfData' );
    this.getLog( this.tcfData );

    if ( this.adObject.cmp[0].tcfStatus === 1 && this.blacklist < 2 && this.ads === 1 ) {
      // this.getCookieString();

      if ( this.tcfData.purpose.consents[1] ) {
        if ( this.tcfData.purpose.consents[2] && this.tcfData.purpose.consents[3] && this.tcfData.purpose.consents[4] && this.tcfData.purpose.consents[5] && this.tcfData.purpose.consents[6] && this.tcfData.purpose.consents[7] && this.tcfData.purpose.consents[8] && this.tcfData.purpose.consents[9] && this.tcfData.purpose.consents[10] ) {
          // personalized_ads
          this.nonPersonalizedAds = false;
          this.myAdKeyords.push( 'personalized_ads' );
          this.getLog( ' nonPersonalizedAds false', 'green' );
          this.pbStorageAllowed = true;
        } else {
          // non_personalized_ads
          this.nonPersonalizedAds = true;
          this.myAdKeyords.push( 'non_personalized_ads' );
          this.getLog( ' non_personalized_ads: true = ' + this.nonPersonalizedAds + ' ', 'red' );
        }
      } else {
        this.cookiesDisable = true;
        this.ltdAdsEnabled = true;
        this.myAdKeyords.push( 'cookies_disable' );
        this.myAdKeyords.push( 'limited_ads' );
        this.getLog( ' cookiesDisable: ' + this.cookiesDisable + ' ', 'red' );
        this.getLog( ' Limited Ads: ' + this.ltdAdsEnabled + ' ', 'red' );
      }
    }

    if ( this.adObject.mediaSize === 1 ) {
      this.mobile_375 = window.matchMedia( '(min-width: 375px)' ).matches;
      this.mobile_700 = window.matchMedia( '(max-width: 700px)' ).matches;
      this.tablet_701 = window.matchMedia( '(min-width: 701px)' ).matches;
      this.tablet_1199 = window.matchMedia( '(max-width: 1199px)' ).matches;
      this.skyscraper_1200 = window.matchMedia( '(min-width: 1200px)' ).matches;
      this.fireplace_1320 = window.matchMedia( '(min-width: 1320px)' ).matches;
      this.sitebar_1460 = window.matchMedia( '(min-width: 1460px)' ).matches;

      if ( this.mobile_375 && this.mobile_700 ) {
        this.getLog( ' mediaSize mobile_375 ', 'seagreen' );
        this.mobile_375 = 1;
        this.myAdKeyords.push( 'mobile_375' );
        this.deviceCategory = 'mobile';
      }
      if ( this.mobile_700 ) {
        this.getLog( ' mediaSize mobile_700 ', 'seagreen' );
        this.mobile_700 = 1;
        this.myAdKeyords.push( 'mobile_700' );
      }
      if ( this.tablet_701 && this.tablet_1199 ) {
        this.getLog( ' mediaSize tablet_701 ', 'seagreen' );
        this.tablet_701 = 1;
        this.myAdKeyords.push( 'tablet_701' );
        this.deviceCategory = 'tablet';
      }
      if ( this.tablet_1199 ) {
        this.getLog( ' mediaSize tablet_1199 ', 'seagreen' );
        this.tablet_1199 = 1;
        this.myAdKeyords.push( 'tablet_1199' );
      }
      if ( this.skyscraper_1200 ) {
        this.getLog( ' mediaSize skyscraper_1200 ', 'seagreen' );
        this.skyscraper_1200 = 1;
        this.myAdKeyords.push( 'skyscraper_1200' );
        this.deviceCategory = 'desktop';
      }
      if ( this.fireplace_1320 ) {
        this.getLog( ' mediaSize fireplace_1320 ', 'seagreen' );
        this.fireplace_1320 = 1;
        this.myAdKeyords.push( 'fireplace_1320' );
        this.deviceCategory = 'desktop';
      }
      if ( this.sitebar_1460 ) {
        this.getLog( ' mediaSize sitebar_1460 ', 'seagreen' );
        this.sitebar_1460 = 1;
        this.myAdKeyords.push( 'sitebar_1460' );
        this.deviceCategory = 'desktop';
      }
    }

    if ( this.adex_segments_str === 'not_set' && this.adObject.adex_api[0].adex_status === 1 ) {

      if (typeof this.adex_cookie !== 'undefined') {
        this.adex_segments_str = this.adex_cookie;
      }
      this.getLog( ' ### ADEX RT API - adex_cookie: ' + this.adex_cookie + ' ###' );
      this.getLog( ' ### ADEX RT API - adex_segments_str: ' + this.adex_segments_str + ' ###' );
    }

    this.getLog('Content-Channel: ' + this.contentChannel);

    if ( this.mobile_700 ) {
      this.bidder.biddingAdSlots = this.adObject.pb[0].pbSlotsMobileList;
    } else {
      this.bidder.biddingAdSlots = this.adObject.pb[0].pbSlotsDesktopList;
    }

    this.integration = this.adObject.headerBidding === 1 && (
      this.getIntegration( 0 ) || this.getIntegration( 1 ) || this.getIntegration( 2 ) || this.getIntegration( 3 ) || this.getIntegration( 4 ) || this.getIntegration( 5 ) || this.getIntegration( 6 ) || this.getIntegration( 7 ) || this.getIntegration( 8 )
    ) && (
      this.getIntegration( 9 ) || this.getIntegration( 10 ) || this.getIntegration( 11 ) || this.getIntegration( 12 )
    ) && (
      this.contentChannel === false
    );

    this.getLog( this.integration );
    this.getLog( adObject );

    this.adQueue = window.adQueue || [];

    this.getLog( this.adQueue );
    this.eventify( this.adQueue, ( updatedArr ) => {
      if ( typeof this.adLogic !== 'undefined' ) {
        for ( let i = 0; i < updatedArr.length; i++ ) {
          this.adLogic( updatedArr[i] );
          updatedArr.splice( i, 1 );
          i--;
        }
      }
    } );
    // this.getAdObjects( adObject );

    if ( this.ads && this.blacklist < 2 ) {
      this.ltdAdsEnabled === false ? this.limitedAds = 0 : this.limitedAds = 1;
      this.getLog( ' GPT ', 'green' );
      this.getLog( ' GPT URL: ' + this.gptLibURL[this.limitedAds] );
      const gads = document.createElement( 'script' );
      gads.async = true;
      gads.type = 'text/javascript';
      // gads.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
      gads.src = this.gptLibURL[this.limitedAds];
      const node = document.getElementsByTagName( 'script' )[0];
      node.parentNode.insertBefore( gads, node );

      // TheADEX DMP load Script
      if ( this.adObject.adex_dmp[0].adex_status === 1 && this.pbStorageAllowed === true && this.adObject.adDomain === 'utopia_de' ) {
        window.dataLayer.push({event: 'utx_ax_p'});
        this.getLog('THE ADEX load', 'blue');
        let adexCidTid = '/' + this.adObject.adex_dmp[0].adex_cid + '/' + this.adObject.adex_dmp[0].adex_tid + '/';
        let adexPageLoginStatus = this.login_status || 'false';
        let adexActiveChannel = dataLayer.getKey( 'activeChannel' ) || '(not set)';
        let adextDeviceCategory = this.deviceCategory || '(not set)';
        let adexContentProvider = dataLayer.getKey( 'contentProvider' ) || '(not set)';
        let adexPageType = dataLayer.getKey( 'bart' ) || '(not set)';
        let adexPageCategory = dataLayer.getKey( 'pageCategory' ) || '(not set)';
        let adexPagePostType = dataLayer.getKey( 'pagePostType' ) || '(not set)';
        let adexPagePostAuthor = dataLayer.getKey( 'pagePostAuthor' ) || '(not set)';
        let adexPagePostID = dataLayer.getKey( 'pagePostID' ) || '(not set)';
        let adexPagePostTags = dataLayer.getKey( 'pageAttributes' ) || '(not set)';
        if ( adexPageType === 'liste') { adexPagePostAuthor = '(not set)'; }
        if ( Array.isArray( adexPageCategory ) ) { adexPageCategory = adexPageCategory.join(); }
        if ( Array.isArray( adexPagePostTags ) ) { adexPagePostTags = adexPagePostTags.join(); }
        let adexTest = [ adexCidTid, 'ut', '_kv', [ { login_status: adexPageLoginStatus, device_category: adextDeviceCategory, active_channel: adexActiveChannel, content_provider: adexContentProvider, page_type: adexPageType, page_category: adexPageCategory, page_post_type: adexPagePostType, page_post_author: adexPagePostAuthor, page_post_id: adexPagePostID, page_post_tags: adexPagePostTags } ] ];
        console.log(adexTest);
        window._adexc.push( adexTest );
        this.getScript( 'the_adex', this.adObject.adex_dmp[0].adex_host + adexCidTid + this.adObject.adex_dmp[0].adex_js, true );
      }

      // Load Adnami Macro
      if ( this.adObject.adnami[0].adnamiStatus === 1 ) {
        this.getScript( 'adnami', this.adObject.adnami[0].adnamiJS, true );
      }

      // ID5 -> GAM
      if (this.adObject.id5[0].id5Status === 1) {
        window.ID5EspConfig = { partnerId: this.adObject.id5[0].id5PartnerID };
      }

      if ( ! this.integration ) {
        this.getLog( ' Header bidding: ' + this.integration + ' ', 'grey' );
      }  else {
        this.getLog( ' Header bidding: ' + this.integration + ' ', 'green' );
        // Prebid
        if ( this.adObject.pb[0].pbStatus === 1 ) {

          let prebidURL = this.adObject.pb[0].pbScript;
          if ( this.devMode === 1 ) {
            prebidURL = this.adObject.pb[0].pbScriptDev;
          }

          this.getLog( ' PREBID : ' + this.adObject.pb[0].pbStatus + ' ', 'prebid' );
          this.pbSlotIncubator = false;
          if ( this.adObject.incubatorStatus === 1 ) {
            this.getLog( ' Incubator : ' + this.adObject.incubator[0] + ' ', 'green' );
            for ( let i = 0; i < window.dataLayer.length; i++ ) {
              if (window.dataLayer[i][this.adObject.incubator[0][0]] !== undefined && window.dataLayer[i][this.adObject.incubator[0][0]] === this.adObject.incubator[0][1]) {
                this.getLog('Incubator : ' + window.dataLayer[i][this.adObject.incubator[0][0]] );
                this.pbSlotIncubator = true;
                this.myAdKeyords.push( 'no_google' );
              }
            }
          }

          // PremiumSkins: Sublime
          if ( this.sitebar_1460 === 1 ) {
            this.getLog( ' Push DesktopSkins ', 'green' );
            this.adObject.pb[0].pbSlotsDesktop.push(this.adObject.pb[0].pbSlotsDesktopSkins[0]);

            // Sublime
            if ( this.adObject.pb[0].pbSublimeStatus === 1 ) {
              /* eslint-disable */
              window.sublime = window.sublime || {};
              window.sublime.notifyId = window.sublime.notifyId || (
                function b( a ) {
                  return a ? (
                    a ^ Math.random() * 16 >> a / 4
                  ).toString( 16 ) : (
                    [1e7] + -1e3 + -4e3 + -8e3 + -1e11
                  ).replace( /[018]/g, b )
                }
              )();
              this.sublimeNotifyId = window.sublime.notifyId;
              this.adObject.pb[0].pbSlotsDesktop.push( {
                code: 'skyscraper',
                mediaTypes: {
                  banner: {
                    sizes: [[1800, 1000]],
                  },
                },
                bids: [
                  {
                    bidder: 'sublime',
                    params: {
                      zoneId: this.adObject.pb[0].pbSublimeZoneId,
                      notifyId: this.sublimeNotifyId,
                    },
                  }
                ],
              } );
              /* eslint-enable */

              // load sublime.js
              this.getScript( 'sublime', this.adObject.pb[0].pbSublimeScript, true );
            }
          }

          // load prebid.js
          this.getScript('pbjs', prebidURL, true );

          if ( this.mobile_700 ) {
            if ( this.pbSlotIncubator ) {
              this.pbjsBids = this.adObject.pb[0].pbSlotsMobileIncubator;
              this.getLog( ' pbSlotsMobileIncubator ', 'blue' );
            } else {
              this.pbjsBids = this.adObject.pb[0].pbSlotsMobile;
            }
          } else {
            if ( this.pbSlotIncubator ) {
              this.pbjsBids = this.adObject.pb[0].pbSlotsDesktopIncubator;
              this.getLog( ' pbSlotsDesktopIncubator ', 'blue' );
            } else {
              this.pbjsBids = this.adObject.pb[0].pbSlotsDesktop;
              this.getLog( ' pbSlotsDesktop ', 'green');
              this.getLog( this.pbjsBids );
            }
          }
          this.bidder.prebid = {
            name: 'prebid',
            requestStatus: false,
            bids: this.pbjsBids,
            reqBids: [],
          };

          window.pbjs.que.push( () => {
            // console.log('+++ pbStorageAllowed: ' + this.pbStorageAllowed + ' +++');
            window.pbjs.bidderSettings = {
              standard: {
                storageAllowed: this.pbStorageAllowed,
              },
              adagio: {
                storageAllowed: this.pbStorageAllowed,
              },
              dspx: {
                storageAllowed: this.pbStorageAllowed,
              },
              gumgum: {
                storageAllowed: this.pbStorageAllowed,
              },
              impactify: {
                storageAllowed: this.pbStorageAllowed,
              },
              justpremium: {
                storageAllowed: this.pbStorageAllowed,
              },
              onetag: {
                storageAllowed: this.pbStorageAllowed,
              },
              rubicon: {
                storageAllowed: this.pbStorageAllowed,
              },
              sovrn: {
                storageAllowed: this.pbStorageAllowed,
              },
              stroeerCore: {
                storageAllowed: this.pbStorageAllowed,
              },
              sublime: {
                storageAllowed: this.pbStorageAllowed,
              },
              teads: {
                storageAllowed: this.pbStorageAllowed,
              },
              triplelift: {
                storageAllowed: this.pbStorageAllowed,
              },
              visx: {
                storageAllowed: this.pbStorageAllowed,
              },
              yieldlab: {
                storageAllowed: this.pbStorageAllowed,
              },
            };

            window.pbjs.setConfig( {
              bidderTimeout: this.bidTimeout,
              deviceAccess: true,
              enableTIDs: true,
              allowActivities: {
                accessDevice: {
                  default: true,
                },
                transmitEids: {
                  default: true,
                },
                transmitTid: {
                  default: true,
                },
              },
              consentManagement: {
                gdpr: {
                  cmpApi: 'iab',
                  timeout: 3000,
                  defaultGdprScope: true,
                  rules: [ {        // these are the default values
                    purpose: 'storage',
                    enforcePurpose: true, // block localStorage based on purpose 1 of TCF
                    enforceVendor: true,  // block localStorage for non consented / non TCF vendors
                    vendorExceptions: [ 'utiqId' ], // configuration line to add to make utiq exception
                  } ],
                },
              },
              firstPartyData: {
                skipEnrichments: true,
              },
              gptPreAuction: {
                enabled: true,
                useDefaultPreAuction: false,
                mcmEnabled: false,
              },
              userSync: {
                syncEnabled: true,
                syncDelay: 3000,
                syncsPerBidder: 5,
                auctionDelay: 1000,
                filterSettings: {
                  iframe: {
                    bidders: ['adagio', 'dspx', 'gumgum', 'impactify', 'justpremium', 'onetag', 'rubicon', 'seedtag', 'sovrn', 'stroeerCore', 'sublime', 'teads', 'triplelift', 'visx', 'yieldlab'],
                    filter: 'include',
                  },
                  image: {
                    bidders: ['adagio', 'dspx', 'gumgum', 'impactify', 'justpremium', 'onetag', 'rubicon', 'seedtag', 'sovrn', 'stroeerCore', 'sublime', 'teads', 'triplelift', 'visx', 'yieldlab'],
                    filter: 'include',
                  },
                },
                userIds: [ {
                  name: 'id5Id',
                  params: {
                    partner: 1198,
                    externalModuleUrl: 'https://cdn.id5-sync.com/api/1.0/id5PrebidModule.js',
                  },
                  storage: {
                    type: 'html5',
                    name: 'id5id',
                    expires: 90,
                    refreshInSeconds: 8 * 3600,
                  },
                }, {
                  name: 'lotamePanoramaId',
                  params: {
                    clientId: '17398',
                  },
                }, {
                  name: 'sharedId',
                  params: {
                    pixelUrl: this.adObject.pb[0].pbSharedIdPixelURL,
                  },
                  storage: {
                    type: 'cookie',
                    name: '_sharedID',
                    expires: 365,
                  },
                }, {
                  name: 'utiqId',
                }, {
                  name: 'utiqMtpId',
                } ],
              },
              cache: {
                url: false,
              },
              useBidCache: false,
              priceGranularity: 'dense',
              currency: {
                adServerCurrency: 'EUR',
                granularityMultiplier: 1,
                defaultRates: { USD: { EUR: 1 } },
              },
            } );
            window.pbjs.refreshFpd();
          } );
        }

        // Amazon Publisher Services (APS)
        if ( this.adObject.aps[0].apsStatus === 1 ) {
          this.getLog( ' APS : ' + this.adObject.aps[0].apsStatus + ' ', 'a9' );
          /* eslint-disable */
          !function( a9, a, p, s, t, A, g ) {
            if ( a[a9] ) return;

            function q( c, r ) {
              a[a9]._Q.push( [c, r] );
            }

            a[a9] = {
              init: function() {
                q( 'i', arguments );
              }, fetchBids: function() {
                q( 'f', arguments );
              }, setDisplayBids: function() {
              }, targetingKeys: function() {
                return [];
              }, _Q: []
            };
            A = p.createElement( s );
            A.async = !0;
            A.src = t;
            g = p.getElementsByTagName( s )[0];
            g.parentNode.insertBefore( A, g );
          }( 'apstag', window, document, 'script', 'https://c.amazon-adsystem.com/aax2/apstag.js' );
          /* eslint-enable */

          // APS Init
          window.apstag.init( {
            pubID: this.adObject.aps[0].apsPubID,
            adServer: this.adObject.aps[0].apsAdServer,
            bidTimeout: this.bidTimeout,
            params: {
              aps_privacy: this.adObject.aps[0].apsPrivacy,
            },
            gdpr: {
              cmpTimeout: this.adObject.aps[0].apsCmpTimeout,
            },
          } );

          if ( this.mobile_700 ) {
            this.apsBids = this.adObject.aps[0].apsSlotsMobile;
          } else {
            this.apsBids = this.adObject.aps[0].apsSlotsDesktop;
          }
          this.bidder.a9 = {
            name: 'a9',
            requestStatus: false,
            bids: this.apsBids,
            reqBids: [],
          };
        }

        if ( this.bidder.prebid ) {
          for ( let j = 0; j < this.bidder.prebid.bids.length; j++ ) {
            this.bidder.biddingAdSlots.push( this.bidder.prebid.bids[j].code );
          }
        }

        if ( this.bidder.a9 ) {
          for ( let j = 0; j < this.bidder.a9.bids.length; j++ ) {
            this.bidder.biddingAdSlots.push( this.bidder.a9.bids[j].slotID );
          }
        }

        this.bidder.biddingAdSlots = this.bidder.biddingAdSlots.filter( this.uniqueArray );
        this.getLog( this.bidder.biddingAdSlots );
        this.requestBids( this.bidder, this.bidTimeout, this.adObject );

      } // headerBidding
    }
  } // constructor

  requestBids( bidder, bidTimeout, adObject ) {
    this.getLog(' Request bidder ', 'green');
    let checkBids = setInterval( getBids, 50 );

    let timeOut = setTimeout( function() {
      clearTimeout( timeOut );
      clearInterval( checkBids );
      window.adlib.getLog( ' Bidder timeout ', 'black' );
      window.adlib.bidder.bidTimeoutBuffer = true;
      window.adlib.getHBAdRefresh();
    }, this.failTimeout );

    if ( adObject.pb[0].pbStatus === 1 ) {
      if ( bidder.prebid.requestStatus === false ) {
        window.pbjs.que.push( function() {
          window.pbjs.addAdUnits( bidder.prebid.bids );
          window.pbjs.requestBids( {
            bidsBackHandler: function( bids ) {
              window.adlib.bidder.prebid.requestStatus = true;
              window.adlib.bidder.prebid.reqBids = bids;
              window.adlib.getLog( ' Prebid bid response ', 'prebid');
            },
            timeout: bidTimeout,
          } );
        } );
      }
    }

    if ( adObject.aps[0].apsStatus === 1 ) {
      if ( bidder.a9.requestStatus === false ) {
        window.apstag.fetchBids( {
          slots: bidder.a9.bids,
          timeout: bidTimeout,
        }, function( bids ) {
          window.adlib.bidder.a9.requestStatus = true;
          window.adlib.bidder.a9.reqBids = bids;
          window.adlib.getLog( ' APS bid response ', 'a9' );
          window.adlib.getLog( bids );
        } );
      }
    }

    let i = 0;
    function getBids() {
      i++;
      window.adlib.getLog(  ' ' + i + '. check bidder response ', 'grey' );
      if ( adObject.pb[0].pbStatus === 1 && adObject.aps[0].apsStatus === 1) {
        if ( window.adlib.bidder.prebid.requestStatus === true && window.adlib.bidder.a9.requestStatus === true ) {
          window.adlib.getLog( ' all bids back ', 'green' );
          stoppBidRequestInterval();
        }
      } else if ( adObject.pb[0].pbStatus === 1 && adObject.aps[0].apsStatus === 0) {
        if ( window.adlib.bidder.prebid.requestStatus === true ) {
          window.adlib.getLog( ' prebid bids back ', 'green' );
          stoppBidRequestInterval();
        }
      } else if ( adObject.pb[0].pbStatus === 0 && adObject.aps[0].apsStatus === 1) {
        if ( window.adlib.bidder.a9.requestStatus === true ) {
          window.adlib.getLog( ' a9 bids back ', 'green' );
          stoppBidRequestInterval();
        }
      }
      function stoppBidRequestInterval() {
        clearTimeout( timeOut );
        clearInterval( checkBids );
        window.adlib.bidder.allBidsBack = true;
        window.adlib.getLog(  window.adlib.bidder );
        window.adlib.getHBAdRefresh();
      }
    }
  } // requestBids

  uniqueArray( value, index, self ) {
    return self.indexOf(value) === index;
  }

  registerPlugins( plugins ) {
    const registered = {};
    plugins.forEach( ( plugin ) => {
      registered[plugin.name] = plugin.cb.bind( this );
    } );
    return registered;
  }

  eventify( arr, callback ) {
    if ( typeof arr === 'object' ) {
      arr.push = function( e ) {
        Array.prototype.push.call( arr, e );
        callback( arr );
      };
    }
  }

  getIntegration( index ) {
    let obj = false;
    for (let i = 0; i < window.dataLayer.length; i++) {
      if (window.dataLayer[i][this.adObject.integration[index][0]] !== undefined && window.dataLayer[i][this.adObject.integration[index][0]] === this.adObject.integration[index][1]) {
        this.getLog('Integration: ' + window.dataLayer[i][this.adObject.integration[index][0]] );
        obj = true;
      }
    }
    return obj;
  }

  getAdTest() {
    const searchParams = new URLSearchParams( window.location.search );
    if ( searchParams.has( 'adTest' ) ) {
      const adTest = searchParams.get( 'adTest' );
      this.myAdKeyords.push( adTest );
    }
    return;
  }

  getBlacklist( uri, adObject ) {
    let blacklist = 0;
    let bList = adObject.blacklistUrls;
    if ( Object.prototype.hasOwnProperty.call( bList, uri ) ) {
      blacklist = bList[uri];
      if ( blacklist === 1 ) {
        this.myAdKeyords.push( 'no_google' );
      }
    }
    return blacklist;
  }

  getScript(scriptName, scriptURL, scriptMode) {
    this.loadScript[scriptName] = document.createElement( 'script' );
    this.loadScript[scriptName].async = scriptMode;
    this.loadScript[scriptName].type = 'text/javascript';
    this.loadScript[scriptName].src = scriptURL;
    this.loadScript[scriptName].addEventListener('load', () => {
      if (scriptName === 'the_adex' && this.adObject.adex_api[0].adex_status === 1 && this.adex_segments_str === 'not_set' ) {
        this.waitForAdexObject().then(( adex ) => {
          this.adexApiCall();
        });
      }
    });
    let scirptNode = document.getElementsByTagName( 'script' )[0];
    scirptNode.parentNode.insertBefore( this.loadScript[scriptName], scirptNode );
    this.getLog( ' getScript: ' + scriptName + ' : ' + scriptURL, 'green' );
  }

  waitForAdexObject() {
    return new Promise((resolve, reject) => {
      const intervalId = setInterval(() => {
        this.getLog( ' waitForAdexObject: ',  window.adex.tracedata );
        if ( window.adex.tracedata ) {
          clearInterval(intervalId);
          resolve(window.adex.tracedata);
        }
      }, 100);
    });
  }

  adexApiCall() {
    // ADEX RT API
    // aktuell nur bei utopia.de
    if (this.adObject.adex_api[0].adex_status === 1 && this.pbStorageAllowed === true && this.adObject.adDomain === 'utopia_de') {
      this.getLog( this.adex_segments_str );

      this.getLog('ADEX RT API - Call', 'blue');
      if ( typeof window.adex.tracedata !== 'undefined' ) {
        this.getLog('### window.adex.tracedata loaded ###', 'green');
        const req = new XMLHttpRequest();
        const url = this.adObject.adex_api[0].adex_uri;
        const token = this.adObject.adex_api[0].adex_token;
        const gdpr = window.adex.tracedata.params.gdpr_consent;
        const gdprHash = window.adex.tracedata.params.adex_consent_hash;

        req.open('GET', url + token + '&gdpr_consent=' + gdpr + '&adex_consent_hash=' + gdprHash, true);
        req.withCredentials = true;
        req.onreadystatechange = function() {
          if ( req.readyState === XMLHttpRequest.DONE && req.status === 200 ) {
            let adexData = JSON.parse( req.responseText ).data.attributes.segments;
            if ( adexData.length > 0 ) {
              window.adlib.getLog( '### ADEX RT API - adex_segments ###');
              adexData.forEach((e) => window.adlib.adex_segments.push(e));
              window.adlib.adex_segments_str = adexData.join();
              window.adlib.getLog( window.adlib.adex_segments );
              window.adlib.setCookie( window.adlib.adObject.adex_api[0].adex_cname, window.adlib.adex_segments, 0 );
            }
          }
        };
        req.send();
      }
    } else {
      this.getLog('### window.adex.tracedata not loaded ###', 'red');
    }
  }

  getCookie(cname) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${cname}=`);
    let cvalue;
    if (parts.length === 2) {
      cvalue = parts.pop().split(';').shift();
    }
    return cvalue;
  }

  setCookie(cname, cvalue, cexpire) {
    this.getLog(' ### SetCookie ### ', 'blue');
    this.getLog('setCookie:' + cname);
    window.dataLayer.push({ event: 'utx_c', utx_cn: cname, utx_cv: cvalue, utx_ce: cexpire });
  }

  debugMode( val ) {
    let debugValue = 'error';
    if ( val === 2 ) {
      window.sessionStorage.setItem( 'adLibDebugMode', 2 );
      if ( this.integration ) {
        window.pbjs.setConfig( {debugging: { enabled: true } } );
      }
      debugValue = val;
    }
    if ( val === 1 ) {
      window.sessionStorage.setItem( 'adLibDebugMode', 1 );
      debugValue = val;
    }
    if ( val === 0 ) {
      window.sessionStorage.setItem( 'adLibDebugMode', 0 );
      if ( this.integration ) {
        window.pbjs.setConfig( {debugging: { enabled: false } } );
      }
      debugValue = val;
    }
    this.getLog( ' debugMode: ' + debugValue + ' ', 'blue' );
  }

  getLog( l, s ) {
    let adLibDebugMode = window.sessionStorage.getItem( 'adLibDebugMode' );
    if ( adLibDebugMode >= 1 ) {
      let styles = [];
      let globalStyles = 'color: #fff; padding:3px 3px 2px;border-radius: 3px;';
      styles.a9 =  [ 'background: #FFA624', globalStyles ].join(';');
      styles.prebid =  [ 'background: #3887C2', globalStyles ].join(';');
      styles.organge =  [ 'background: #D95B43', globalStyles ].join(';');
      styles.red =  [ 'background: #B31A38', globalStyles ].join(';');
      styles.blue = [ 'background: #26ADE4', globalStyles ].join(';');
      styles.darkblue = [ 'background: #013976', globalStyles ].join(';');
      styles.green = [ 'background: #8A9B0F', globalStyles ].join(';');
      styles.seagreen = [ 'background: #79BD9A', globalStyles ].join(';');
      styles.bluegrey = [ 'background: #99B2B7', globalStyles ].join(';');
      styles.grey = [ 'background: #556270', globalStyles ].join(';');
      styles.black = [ 'background: #000', globalStyles ].join(';');
      styles.lila = [ 'background: #550055', globalStyles ].join(';');
      if ( typeof( s ) !== 'undefined'  ) {
        console.log('%c' + l , styles[s] ); // eslint-disable-line
      } else {
        console.log( l ); // eslint-disable-line
      }
    }
    return;
  }

  getSlotStatus( slotId, state ) {
    this.getLog( slotId + ' - ' + state);
  }

  getAutomaticAds( adObject ) {
    if ( adObject.adsense[0].automaticAds === 1 && this.blacklist === 0 && this.nonPersonalizedAds === false ) {
      this.getLog( 'adsenseClientCAId: ' + adObject.adsense[0].adsenseClientCAId );
      const autoads = document.createElement( 'script' );
      autoads.async = true;
      autoads.type = 'text/javascript';
      const autoUseSSL = document.location.protocol === 'https:';
      autoads.src = (
        autoUseSSL ? 'https:' : 'http:'
      ) + '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
      const autoadsnode = document.getElementsByTagName( 'script' )[1];
      autoadsnode.parentNode.insertBefore( autoads, autoadsnode );
      ( this.adsbygoogle = window.adsbygoogle || [] ).requestNonPersonalizedAds = false;
      ( this.adsbygoogle = window.adsbygoogle || [] ).pauseAdRequests = 0;
      ( this.adsbygoogle = window.adsbygoogle || [] ).push( {
        google_ad_client: adObject.adsense[0].adsenseClientCAId,
        enable_page_level_ads: true,
      } );
    }
  }

  getAdObjects( obj ) {
    const adUnitLength = obj.adUnitCodes.length > 0 ? obj.adUnitCodes.length : 0;
    for ( let i = 0; i < adUnitLength; i++ ) {
      const adUnitClassId = '#' + obj.adUnitCodes[i].classId;
      const adUnit = document.querySelector( adUnitClassId );
      if ( adUnit !== null ) {
        if ( obj.adUnitCodesLogic[0].status === 1 && ( adUnitClassId === '#superbanner' || adUnitClassId === '#Billboard' ) ) {
          this.getLog( ' wallpaper status = ' + obj.adUnitCodesLogic[0].status );
        } else {
          if ( adUnit.classList.contains( 'lazyload' ) ) {
            this.getLog( 'LazyLaod: ' + adUnitClassId );
          } else {
            this.getLog( ' getAdObjects adQueue ' + adUnitClassId + ' ', 'lila' );
          }
        }
      }
    }
  }

  adCall( obj ) {

    const adUnitCodes = this.adObject.adUnitCodes;
    const adUnitLength = adUnitCodes.length > 0 ? adUnitCodes.length : 0;

    const sizes = function( AdObject ) {
      const adUnitMapping = window.googletag.sizeMapping();
      for ( let i = 0; i < AdObject.breakingPoints.length; i++ ) {
        adUnitMapping.addSize( AdObject.breakingPoints[i][0], AdObject.breakingPoints[i][1] );
      }
      return adUnitMapping.build();
    };

    this.getLog( this.bidder.definedAdSlots );

    // The Adex DMP
    if ( this.adObject.adex_dmp[0].adex_status === 1 && this.pbStorageAllowed === true && this.integration === true) {
      this.getLog( ' adex: ' + this.adex_segments_str );
    }

    // const domElement = document.getElementById(obj.data.adSlot);
    // this.getLog( '###' + domElement + '###' );

    for ( let i = 0; i < adUnitLength; i++ ) {
      if ( obj.data.adSlot === adUnitCodes[i].classId ) {
        const myAdObject = adUnitCodes[i];

        this.getLog( 'define slot: ' + obj.data.adSlot );
        this.bidder.definedAdSlots.push( obj.data.adSlot );
        this.bidder.definedAdSlots = this.bidder.definedAdSlots.filter( this.uniqueArray );
        this.getLog( this.bidder.definedAdSlots );

        if ( this.adObject.stickyAds[0].stickyAdStatus === 1 ) {
          if (adUnitCodes[i].classId === this.adObject.stickyAds[0].stickyAdUnitRectangle_1) {
            stickybits('#' + this.adObject.stickyAds[0].stickyAdUnitRectangle_1, { useStickyClasses: true});
          }
          if (adUnitCodes[i].classId === this.adObject.stickyAds[0].stickyAdUnitContent_1) {
            stickybits('#' + this.adObject.stickyAds[0].stickyAdUnitContent_1, { useStickyClasses: true });
          }
        }

        let expandAdSlot = adUnitCodes[i].expanded;
        let collapseAdSlot = undefined;
        if ( expandAdSlot ) {
          collapseAdSlot = true;
        }

        window.googletag.cmd.push( () => {
          const adUnit = this.adObject.publisherDfpAdUrl + '/' + window.adZone;
          // const adUnit = this.adObject.publisherDfpAdUrl + '/' + window.adZone + '/' + this.deviceCategory + '/' + myAdObject.classId;
          // is OutOfPage
          if ( myAdObject.classId === 'OutOfPage') {
            this.getLog('### OutOfPage ### ' + myAdObject.classId + ' ', 'lila' );
            this.adSlots[myAdObject.classId] = window.googletag.defineOutOfPageSlot( adUnit, myAdObject.classId )
              .addService( window.googletag.pubads() )
              .setTargeting( 'placement', myAdObject.classId )
              .setTargeting( 'kw', [this.myAdKeyords] )
              .setTargeting( 'ad_provider', this.adObject.adProvider )
              .setTargeting( 'ad_domain', this.adObject.adDomain )
              .setTargeting( 'adex', this.adex_segments_str  )
              .setTargeting( 'u_ls', this.login_status );
          } else {
            this.adSlots[myAdObject.classId] = window.googletag.defineSlot( adUnit, myAdObject.sizes, myAdObject.classId )
              .addService( window.googletag.pubads() )
              .defineSizeMapping( sizes( myAdObject ) )
              .setCollapseEmptyDiv( expandAdSlot, collapseAdSlot )
              .setTargeting( 'placement', myAdObject.classId )
              .setTargeting( 'kw', [this.myAdKeyords] )
              .setTargeting( 'ad_provider', this.adObject.adProvider )
              .setTargeting( 'ad_domain', this.adObject.adDomain )
              .setTargeting( 'adex', this.adex_segments_str )
              .setTargeting( 'u_ls', this.login_status );
          }
          this.getLog(' wait ' + myAdObject.classId + ' ', 'organge' );

          window.googletag.pubads().disableInitialLoad();
          window.googletag.pubads().enableSingleRequest();
          window.googletag.enableServices();
          this.getLog( this.adSlots[myAdObject.classId] );
        } );

        if ( this.pageType === 'homepage' && myAdObject.classId === 'skyscraper' ) {
          this.getLog('Home & Sky');
        }

        if ( !this.integration || !this.getAdSlot( myAdObject.classId) ) {
          // wenn der AdSlot nicht im Header bidding ist und direkt ausgeführt werden soll
          window.googletag.cmd.push( () => {
            this.getLog( ' adSlot adRefresh: ' + myAdObject.classId + ' ', 'green');
            // window.googletag.display( myAdObject.classId );
            // window.googletag.pubads().setCookieOptions( this.cookiesDisable );
            window.googletag.pubads().setPrivacySettings({ 'nonPersonalizedAds': this.nonPersonalizedAds }); // eslint-disable-line
            if ( this.ltdAdsEnabled ) {
              window.googletag.pubads().setPrivacySettings({ 'limitedAds': this.ltdAdsEnabled }); // eslint-disable-line
            }
            // window.adlib.getLog( ' cookiesDisable: ' + this.cookiesDisable + ' ', 'bluegrey' );
            window.adlib.getLog( ' nonPersonalizedAds: ' + this.nonPersonalizedAds + ' ', 'bluegrey' );
            window.adlib.getLog( ' limitedAds: ' + this.ltdAdsEnabled + ' ', 'bluegrey' );
            this.adRefresh( myAdObject.classId );
            this.getAdEvent( myAdObject );
          } );
        } else {
          // wenn der AdSlot später aufgerufen wird
          if ( this.integration && this.getAdSlot( myAdObject.classId) ) {
            // Header bidding Nachzügler
            this.getLog( ' getHBAdRefresh: ' + myAdObject.classId + ' ' );
            this.getHBAdRefresh( myAdObject );
          }
        }
      }
    }
  }

  getAdSlot( adSlotID ) {
    let that = this;
    let hbAdSlotsLength = that.bidder.biddingAdSlots.length;
    let adSlotStatus = false;
    for ( let j = 0; j < hbAdSlotsLength; j++ ) {
      if ( adSlotID === that.bidder.biddingAdSlots[j] ) {
        // that.getLog('getAdSlot: ' + adSlotID + ' = ' + that.bidder.biddingAdSlots[j] + ' ', 'seagreen');
        adSlotStatus = true;
      }
    }
    // that.getLog('adSlotStatus: ' + adSlotStatus + ' ', 'grey');
    return adSlotStatus;
  }

  getAdUnitCodes( obj ) {
    const adUnitCodes = this.adObject.adUnitCodes;
    const adUnitLength = adUnitCodes.length > 0 ? adUnitCodes.length : 0;
    let myAdUnit = [];
    for ( let i = 0; i < adUnitLength; i++ ) {
      if ( obj === adUnitCodes[i].classId ) {
        myAdUnit = adUnitCodes[i];
      }
    }
    return myAdUnit;
  }

  getHBAdRefresh( ) {
    let biddingAdSlotsCount = this.bidder.biddingAdSlots.length;
    let myAdUnit = [];
    if ( window.googletag && window.googletag.apiReady && window.googletag.pubads && window.googletag.pubads().refresh ) {
      // window.googletag.pubads().setCookieOptions( this.cookiesDisable );
      window.googletag.pubads().setPrivacySettings({ 'nonPersonalizedAds': this.nonPersonalizedAds }); // eslint-disable-line
      if ( this.ltdAdsEnabled ) {
        window.googletag.pubads().setPrivacySettings({ 'limitedAds': this.ltdAdsEnabled }); // eslint-disable-line
      }

      // window.adlib.getLog( ' cookiesDisable: ' + this.cookiesDisable + ' ', 'darkblue' );
      window.adlib.getLog( ' nonPersonalizedAds: ' + this.nonPersonalizedAds + ' ', 'darkblue' );
      window.adlib.getLog( ' limitedAds: ' + this.ltdAdsEnabled + ' ', 'darkblue' );

      for ( let i = 0; i < biddingAdSlotsCount; i++ ) {
        let  indexItem = this.bidder.definedAdSlots.indexOf( this.bidder.biddingAdSlots[i] );
        if ( indexItem >= 0 ) {
          myAdUnit = this.getAdUnitCodes( this.bidder.biddingAdSlots[i] );
          this.bidder.refreshedAdSlots[0] = this.bidder.definedAdSlots.splice( indexItem, 1 );
          window.googletag.cmd.push( function() {
            window.apstag.setDisplayBids();
            window.pbjs.setTargetingForGPTAsync( window.adlib.bidder.refreshedAdSlots[0] );
            window.googletag.pubads().refresh( [ window.adlib.adSlots[ window.adlib.bidder.refreshedAdSlots[0] ] ] );
            window.adlib.getLog( ' adRefresh: ' + window.adlib.bidder.refreshedAdSlots[0] + ' ', 'green' );
          } );
          this.getAdEvent( myAdUnit );
        }
      }
    }
  }

  adRefresh( myAdSlot ) {
    if (window.googletag && window.googletag.apiReady && window.googletag.pubads && window.googletag.pubads().refresh ) {
      this.getLog( ' adRefresh => ' + myAdSlot + ' ', 'lila' );
      window.googletag.pubads().refresh( [window.adlib.adSlots[myAdSlot]] );
    }
  }

  getAdEvent( myAdObject ) {
    this.getLog( 'Function adEvent: ' );
    window.googletag.cmd.push( () => {
      window.googletag.pubads().addEventListener( 'slotRenderEnded', event => {
        if ( !event.isEmpty ) {
          if ( event.slot.getSlotElementId() === myAdObject.classId ) {
            this.getLog( 'adEvent: ' + event.slot.getSlotElementId() );
            // adDeclaration check
            if ( myAdObject.adDeclaration ) {
              document.querySelector( '#' + myAdObject.classId ).classList.add( 'adDeclaration', 'adDecoration' );
            }
            this.getLog( event.slot.getSlotElementId() + ' | adW: ' + event.size[0] + ' | adH: ' + event.size[1] + ' | WP: ' + this.adObject.adUnitCodesLogic[0].status );

            if ( this.devMode === 1 ) {
              this.getLog( ' AdSlot Info', 'green'  );
              this.getLog( 'Advertiser ID: ' + event.advertiserId );
              this.getLog( 'Campaign ID: ' + event.campaignId );
              this.getLog( 'Company IDs: ' + event.companyIds );
              this.getLog( 'Creative ID: ' + event.creativeId );
              this.getLog( 'Creative Template ID: ' + event.creativeId );
              this.getLog( 'Is backfill? ' + event.isBackfill );
              this.getLog( 'Is empty? ' + event.isEmpty );
              this.getLog( 'Label IDs: ' + event.labelIds );
              this.getLog( 'Line Item ID: ' + event.lineItemId );
              this.getLog( 'Size: ' + event.size.join('x') );
              this.getLog( 'Slot content changed? ' + event.slotContentChanged );
              this.getLog( 'Source Agnostic Creative ID: ' + event.sourceAgnosticCreativeId );
              this.getLog( 'Source Agnostic Line Item ID: ' + event.sourceAgnosticLineItemId );
              this.getLog( 'Yield Group IDs: ' + event.yieldGroupIds );
              this.getLog( 'adex: ' + this.adex_segments_str);
              console.log( 'dataLayer: ', window.dataLayer[1] );
            }

            // skyscraper event.size[0] === 161 oder 1
            if ( event.slot.getSlotElementId() === 'skyscraper' && ( event.size[0] === ( 161 ) || event.size[0] === ( 1 ) ) && this.adObject.adUnitCodesLogic[0].status === 1 ) {
              this.getLog( ' Wallpaper ', 'green' );
              this.getLog( event.size[0] );
            }
            if ( event.slot.getSlotElementId() === 'skyscraper' && ( event.size[0] === ( 120 ) || event.size[0] === ( 160 ) || event.size[0] === ( 300 ) ) && this.adObject.adUnitCodesLogic[0].status === 1 ) {
              this.getLog( ' adDomain: ' + this.adObject.adDomain );
              this.getLog( ' kein wallpaper ', 'grey' );
              this.getLog( event.size[0] );
              // stickySky
              // this.stickySky();
              this.adObject.adUnitCodesLogic[0].status = 0;
              if ( this.adObject.adDomain === 'oekotest_de' ) {
                window.whenAvailable( '#superbanner', () => this.adQueue.push( {
                  type: 'adCall',
                  data: {adSlot: 'superbanner'},
                } ) );
              } else if ( this.adObject.adDomain === 'utopia_de' ) {
                this.adQueue.push( { type: 'adCall', data: {adSlot: 'Billboard'} } );
              } else {
                this.getLog( ' adQueue Billboard ', 'lila' );
                this.adQueue.push( {type: 'adCall', data: {adSlot: 'Billboard'}} );
              }
            }
          }
        } else {
          if ( event.slot.getSlotElementId() === 'skyscraper' && this.adObject.adUnitCodesLogic[0].status === 1 ) {
            this.getLog( ' kein wallpaper ', 'black' );
            this.getLog( ' adDomain: ' + this.adObject.adDomain );
            this.adObject.adUnitCodesLogic[0].status = 0;
            if (this.adObject.adDomain === 'oekotest_de') {
              window.whenAvailable( '#superbanner', () => this.adQueue.push( {
                type: 'adCall',
                data: {adSlot: 'superbanner'},
              } ) );
            } else if ( this.adObject.adDomain === 'utopia_de' ) {
              this.adQueue.push( { type: 'adCall', data: {adSlot: 'Billboard'} } );
            } else {
              this.getLog( ' adQueue Superbanner ', 'lila' );
              this.adQueue.push( {type: 'adCall', data: {adSlot: 'superbanner'}} );
            }
          }
        }
      } );
    } );
  }

  impressiontracking( obj ) {
    append( 'body', '<img src="' + obj.data.impressiontracking + '" border="0" height="1" width="1" alt="">' );
  }

  hideAdSlot( adSlotID ) {
    const elems = document.querySelectorAll( adSlotID );
    elems.forEach( ( elem ) => {
      elem.style.display = 'none';
    } );
  }

  removeAdSlot( adSlotID ) {
    const elems = document.querySelectorAll( adSlotID );
    elems.forEach( ( elem ) => {
      elem.parentNode.removeChild( elem );
    } );
  }

  background( obj ) {
    if ( obj.data.background ) {
      const body = document.querySelector( 'body' );
      if ( obj.data.backgroundcolor.length === 7 ) {
        body.style.backgroundColor = obj.data.backgroundcolor;
      }
      if ( obj.data.backgroundimage.length > 1 ) {
        body.style.backgroundImage = 'url(' + obj.data.backgroundimage + ')';
        body.style.backgroundSize = obj.data.backgroundsize;
        body.style.backgroundAttachment = obj.data.backgroundattachment;
      }
    }
  }

  stickySky() {
    this.getLog(' stickySky ', 'orange');
    document.getElementById('main_wrapper').appendChild(document.getElementById('skyscraper'));
    stickybits( '#skyscraper', { useStickyClasses: true } );
  }

  skyscraperpositionfixed( obj ) {
    const elems = document.querySelectorAll( '#skyscraper_new, #left_skyscraper_new' );
    elems.forEach( ( elem ) => {
      elem.style.position = obj.data.skyscraperposition;
    } );

    const stickySkyscraperTop = window.adlib.adObject.stickySkyscraperTop;

    function calcWpSpace( stickySkyscraperTop ) {
      let bb = document.getElementsByClassName( 'superbanner' );
      let sky = document.getElementById( 'skyscraper_new' );
      let rect = bb[0].getBoundingClientRect();
      let t = '' + rect.top + 'px';
      if ( rect.top < 0 ) {t = stickySkyscraperTop;}
      if ( obj.data.ad === 'fireplace' ) {
        let leftsky = document.getElementById('left_skyscraper_new');
        if ( leftsky !== 'undefined' ) {
          leftsky.style.setProperty('top', t);
        }
      }
      sky.style.setProperty('top', t);
    }
    setInterval(function() { calcWpSpace( stickySkyscraperTop ); }, 500);
  }

  skyscraperpositionabsolute( obj ) {
    const elems = document.querySelectorAll( '#skyscraper_new, #left_skyscraper_new' );
    elems.forEach( ( elem ) => {
      elem.style.position = obj.data.skyscraperposition;
      elem.style.top = Number( obj.data.skyscraperpositiontop ) + 'px';
    } );
  }

  wallpaper( obj ) {
    if ( window.SkyscraperMinWidth.matches === false ) {
      this.getLog( obj );
      this.removeAdSlot( '#superbanner' );
      this.removeAdSlot( '#Billboard' );
      this.removeAdSlot( '#skyscraper' );
      const superbannerposition = 1000 - Number( obj.data.superbannerwidth ) + Number( obj.data.superbannermarginleft );
      if ( obj.data.integration === 'image' ) {
        if ( obj.data.superbannerimg.length > 0 ) {
          insertBefore( '#wpskyscraper', '<div class="superbanner" id="superbanner_new" style="float: left; width: ' + obj.data.superbannerwidth + 'px ; height: ' + obj.data.superbannerheight + 'px; margin-left:' + superbannerposition + 'px"><a href="' + obj.data.klickziel + '" target="_blank"><img src="' + obj.data.superbannerimg + '" width="' + obj.data.superbannerwidth + '" height="' + obj.data.superbannerheight + '" alt=""></a></div>' );
        }
        if ( obj.data.skyscraperimg.length > 0 ) {
          insertBefore( '#wpskyscraper', '<div class="skyscraper" id="skyscraper_new" style=""><a href="' + obj.data.klickziel + '" target="_blank"><img src="' + obj.data.skyscraperimg + '" width="' + obj.data.skyscraperwidth + '" height="' + obj.data.skyscraperheight + '" alt=""></a></div>' );
        }
        if ( obj.data.ad === 'fireplace' && obj.data.left_skyscraperimg.length > 0 ) {
          insertBefore( '#wpskyscraper', '<div class="left_skyscraper skyscraperleft" id="left_skyscraper_new" style="width: ' + obj.data.left_skyscraperwidth + 'px ; height: ' + obj.data.left_skyscraperheight + 'px; margin-left: -' + obj.data.left_skyscraperwidth + 'px"><a href="' + obj.data.klickziel + '" target="_blank"><img src="' + obj.data.left_skyscraperimg + '" width="' + obj.data.left_skyscraperwidth + '" height="' + obj.data.left_skyscraperheight + '" alt=""></a></div>' );
        }
      } else if ( obj.data.integration === 'iframe' ) {
        if ( obj.data.superbanneriframe.length > 0 ) {
          insertBefore( '#wpskyscraper', '<div class="superbanner" id="superbanner_new" style="float: left; width: ' + obj.data.superbannerwidth + 'px; height: ' + obj.data.superbannerheight + 'px; margin-left:' + superbannerposition + 'px"><iframe src="' + obj.data.superbanneriframe + '" width="' + obj.data.superbannerwidth + '" height="' + obj.data.superbannerheight + '" scrolling="no"></iframe></div>' );
        }
        if ( obj.data.skyscraperiframe.length > 0 ) {
          insertBefore( '#wpskyscraper', '<div class="skyscraper" id="skyscraper_new" style=""><iframe src="' + obj.data.skyscraperiframe + '" width="' + obj.data.skyscraperwidth + '" height="' + obj.data.skyscraperheight + '" scrolling="no"></iframe></div>' );
        }
        if ( obj.data.ad === 'fireplace' && obj.data.left_skyscraperiframe.length > 0 ) {
          insertBefore( '#wpskyscraper', '<div class="left_skyscraper skyscraperleft" id="left_skyscraper_new" style="width: ' + obj.data.left_skyscraperwidth + 'px ; height: ' + obj.data.left_skyscraperheight + 'px; margin-left: -' + obj.data.left_skyscraperwidth + 'px"><iframe src="' + obj.data.left_skyscraperiframe + '" width="' + obj.data.left_skyscraperwidth + '" height="' + obj.data.left_skyscraperheight + '" scrolling="no"></iframe></div>' );
        }
      }

      this.hideAdSlot( '#wpskyscraper' );
      if ( obj.data.impressiontracking.length > 0 ) {
        this.impressiontracking( obj );
      }
      if ( obj.data.background ) {
        this.background( obj );
      }
      if ( obj.data.skyscraperposition === 'fixed' ) {
        this.skyscraperpositionfixed( obj );
      }
      if ( obj.data.skyscraperposition === 'absolute' ) {
        this.skyscraperpositionabsolute( obj );
      }
    }
  }

  contentFeed( obj ) {
    this.getLog( ' recommendation_feed ' + obj.data.feed, 'blue' );
    this.getLog( obj );
    // this.getLog( obj );
    if ( obj.data.feed === 'taboola_video' || obj.data.feed === 'taboola' ) {
      append( '#recommendation_feed', '<div class="content_empfehlung_head">' + obj.data.headline + '</div><div id="' + obj.data.container + '" class="content_empfehlungen"></div>' );
      /* eslint-disable */
      window._taboola = window._taboola || [];
      _taboola.push( { article:'auto' } );
      !function(e, f, u, i) {
        if (!document.getElementById( i )){
          e.async = 1;
          e.src = u;
          e.id = i;
          f.parentNode.insertBefore( e, f );
        }
      }( document.createElement('script'),
        document.getElementsByTagName('script')[0],
        obj.data.network_string,
        'tb_loader_script' );
      if (window.performance && typeof window.performance.mark == 'function') {
        window.performance.mark('tbl_ic');
      }
      _taboola.push( {
        mode: obj.data.mode, // 'alternating-thumbnails-a',
        container: obj.data.container, // 'taboola-below-article-thumbnails',
        placement: obj.data.placement, // 'Below Article Thumbnails',
        target_type: obj.data.target_type, // 'mix'
      } );
      _taboola.push( { flush: true } );
      /* eslint-enable */
    }
    if ( obj.data.feed === 'outbrain' ) {
      this.getLog( ' recommendation_feed outbrain ', 'green' );
      append( '#recommendation_feed', '<div class="OUTBRAIN" data-src="' + this.canonicalURI + '" data-widget-id="' + obj.data.container + '"></div>' );
      this.getScript('outbrain', 'https://widgets.outbrain.com/outbrain.js', true);
    }

    if ( obj.data.impressiontracking.length > 0 ) {
      this.impressiontracking( obj );
    }
  }

  anchorAd( obj ) {
    if ( obj.data.ad === 'outbrain_read_next' ) {
      this.getLog( ' outbrain_read_next ', 'green' );
      append( '.ShariffSC.ut-social-share', '<div class="OUTBRAIN" data-src="' + this.canonicalURI + '" data-widget-id="' + obj.data.container + '"></div>' );
      this.getScript('outbrain', 'https://widgets.outbrain.com/outbrain.js', true);
    }
  }

  closeModal() {
    this.getLog( ' Modal close ', 'blue' );
    document.getElementById('utModal').remove();
    this.enableScroll();
  }

  disableScroll() {
    document.body.classList.add('stop-scrolling');
  }

  enableScroll() {
    document.body.classList.remove('stop-scrolling');
  }

  utModal( obj ) {
    this.getLog( obj );

    if ( obj.data.integration === 'banner' ) {
      this.getLog( ' Modal ' + obj.data.integration, 'blue' );
    }
    if ( obj.data.integration === 'iframe' ) {
      this.getLog( ' Modal ' + obj.data.integration, 'blue' );
    }
    if ( obj.data.integration === 'native' ) {
      this.getLog( ' Modal ' + obj.data.integration + ' show ', 'blue' );
      this.disableScroll();
      insertBefore( '#OutOfPage', '<div id="utModal" class="ut-overlay">\n' +
                                  '  <div class="ut-overlay-dialog">\n' +
                                  '    <div class="ut-overlay-content">\n' +
                                  '      <div class="ut-overlay-body">\n' +
                                  '        <div class="fullwidth center">\n' +
                                  '          <button type="button" class="close" onclick="window.adlib.closeModal();"><span class="fs14 fw300">schließen</span>\n' +
                                  '          </button>\n' +
                                  '        </div>\n' +
                                  '        <div class="fullwidth pb20 center cblack">\n' +
                                  '          <a href="' + obj.data.klickziel + '" target="_blank" title="' + obj.data.native_headline + '">\n' +
                                  '            <img src="' + obj.data.native_image + '"\n' +
                                  '                 title="' + obj.data.native_headline + '" alt="' + obj.data.native_headline + '" class="img">\n' +
                                  '            <p class="h3 roboto cblack fw500 fs20 pt20 ">' + obj.data.native_headline + '</p>\n' +
                                  '            <p class="fs16 cblack fw300 p20">' + obj.data.native_copytext + '</p>\n' +
                                  '          </a>\n' +
                                  '          <div><a href="' + obj.data.klickziel + '" title="' + obj.data.native_headline + '" class="RedAutowidth fw300 center">' + obj.data.native_cta_text + '</a>\n' +
                                  '          </div>\n' +
                                  '        </div>\n' +
                                  '      </div>\n' +
                                  '    </div>\n' +
                                  '  </div>\n' +
                                  '<img src="' + obj.data.img_view + '" style="display:none"></div>');
    }
  }

  utiqConsent( obj ) {
    this.getLog( ' Utiq Loader ', 'blue' );
    this.getLog( obj );
    if ( obj.data.integration === 'consent' ) {
      this.getScript( 'utiq', this.adObject.pb[0].pbUtiq.utiqLoaderURL, true );
      window.dataLayer.push( {
        event: 'utiq_pageview',
      } );
    }

    // Utiq Config
    window.Utiq = window.Utiq || {};
    window.Utiq.config = {
      consentManagerDataLayer: true,
      listeners: {
        onConsentManagerStatusChanged: ( statusObj ) => {
          console.log( statusObj.status );
          window.dataLayer.push( {
            event: 'utiq_popup',
            utiq_status: statusObj.status,
          } );
        },
        onConsentChanging: ( isConsentGrantedObj ) => {
          console.log('isConsentGranted');
          console.log(isConsentGrantedObj.isConsentGranted);
          window.dataLayer.push({
            event: 'utiq_consent',
            utiq_consent_granted: isConsentGrantedObj.isConsentGranted,
          });
        },
      },
    };
  }

  adLogic( obj ) {
    if ( obj.type === 'adData' ) {
      if ( obj.data.ad === 'wallpaper' || obj.data.ad === 'fireplace' ) {
        return this.wallpaper( obj );
      }
      if ( obj.data.ad === 'contentFeed' ) {
        return this.contentFeed( obj );
      }
      if ( obj.data.ad === 'anchorAd' ) {
        return this.anchorAd( obj );
      }
      if ( obj.data.ad === 'modal' ) {
        return this.utModal( obj );
      }
      if ( obj.data.ad === 'utiqConsent' ) {
        return this.utiqConsent( obj );
      }
    }
    if ( obj.type === 'adCall' ) {
      let slot = obj.data.adSlot;
      let adAction = '';
      if ( Object.prototype.hasOwnProperty.call( this.adSlots, slot ) ) {
        this.getLog( 'REFRESH AdSlot: ' + slot );
        adAction = this.adRefresh( slot );
      } else {
        this.getLog( 'adCall: ' + obj.data.adSlot );
        adAction = this.adCall( obj );
      }
      return adAction;
    }
    if ( obj.type === 'fake' ) {
      return null;
    }

    return null;
  }
}
